//@ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSnapshot } from "valtio";
import { vState, ConfigLink, Api, ServiceStorage } from "../../../core";
import { useHistory } from "react-router-dom";
import { TiPlus } from "react-icons/ti";
import { Button, Text } from "../../components";
import { HiHome, HiUserCircle, HiQrcode } from "react-icons/hi";
import srcImg from "../../../assets/images/footer-back.svg";
import { FooterButton } from "./FooterButton";

interface IFooterProps {
  className?: string;
  activeTab?: number;
}

const Footer: React.FC<IFooterProps> = React.memo(({ className = "", activeTab = 0 }) => {
  const snap = useSnapshot(vState);
  const history = useHistory();

  const homeClickHandler = () => {
    history.push(ConfigLink.homePage);
  };

  const plateRegisterHandler = () => {
    history.push(ConfigLink.licenseRegistration);
  };

  const profileClickHandler = () => {
    history.push(ConfigLink.profile);
  };

  return (
    <>
      <div
        // style={{
        //   backgroundImage: `url(${srcImg})`,
        // }}
        className={`ts-footer w-full fixed bottom-0 px-6 pt-9 flex justify-center items-center z-40 ${className}`}
      >
        <div className="w-full flex justify-between items-center">
          <FooterButton
            isActive={activeTab === 2}
            icon={<HiUserCircle size={19} className={`${activeTab === 2 ? "ts-primary" : "ts-accent"} mb-1.5`} />}
            title="پروفایل"
            onClick={profileClickHandler}
          />

          <FooterButton
            isActive={activeTab === 1}
            icon={<HiQrcode size={19} className={`${activeTab === 1 ? "ts-primary" : "ts-accent"} mb-1.5`} />}
            title="ثبت پلاک"
            onClick={plateRegisterHandler}
          />

          <FooterButton
            isActive={activeTab === 0}
            icon={<HiHome size={19} className={`${activeTab === 0 ? "ts-primary" : "ts-accent"} mb-1.5`} />}
            title="داشبورد"
            onClick={homeClickHandler}
          />
        </div>
      </div>
    </>
  );
});

export { Footer };

const styles = {};
