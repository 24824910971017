/* eslint-disable */
import React from "react";

interface IFullDividerProps {
  className?: string;
}

const FullDivider: React.FC<IFullDividerProps> = React.memo(({ className }) => {
  return <div className={`ts-divider ${className}`} />;
});

export { FullDivider };
