import Axios from "axios";
import { configs } from "../../configs";

const env = import.meta.env;

export const neshanAPI = Axios.create({
  baseURL: `https://api.neshan.org`,
});

neshanAPI.interceptors.request.use(
  (config) => {
    config.headers = {
      ...(config.headers || {}),
      "Api-key": configs().locationToAddressApi,
    };

    return config;
  },
  (e) => Promise.reject(e),
);

neshanAPI.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    return Promise.reject(err);
  },
);
