/* eslint-disable */
import React from "react";
import { Text } from "../Text";
import { BiErrorAlt } from "react-icons/bi";
import noAccess from "../../../assets/images/no-access.svg";

const UnauthorizedAccess = ({}) => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <img src={noAccess} alt="unauthorized-access" />
      <div className="w-full px-6 flex flex-col justify-center items-center mt-20">
        <Text type="H5" className="ts-gray-07 text-center mb-3">
          شما به این برنامه دسترسی ندارید!
        </Text>

        <Text type="P4" className="ts-gray-07 text-center">
          در حال تغییر مسیر به صفحه ورود...
        </Text>
      </div>
    </div>
  );
};

export { UnauthorizedAccess as default };

const styles = {};
