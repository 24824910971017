/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";

import SwipeableBottomSheet from "@sergeymyssak/swipeable-bottom-sheet";
import { useMediaQuery } from "react-responsive";

interface IBottomModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  modalClassName?: string;
  hasShadow?: boolean;
  forPWA?: boolean;
  staticBackdrop?: boolean;
  centerScreen?: boolean;
  children: any;
  noPadding?: boolean;
  carModal?: boolean;
}

const BottomModal: React.FC<IBottomModalProps> = React.memo(
  ({
    children,
    isOpen,
    onClose,
    hasShadow = false,
    centerScreen = false,
    forPWA = false,
    staticBackdrop = false,
    className = "",
    modalClassName = "",
    noPadding = false,
    carModal = false,
  }) => {
    const isLargeScreen = useMediaQuery({
      query: "(min-width: 481px)",
    });

    const [isTime, setIsTime] = useState(false);

    useEffect(() => {
      if (!isOpen) {
        setTimeout(() => {
          setIsTime(true);
        }, 600);
      } else {
        setIsTime(false);
      }
    }, [isOpen]);

    return isLargeScreen || centerScreen ? (
      <Modal
        open={isOpen}
        onClose={onClose}
        className={`${modalClassName}`}
        backdrop={forPWA || staticBackdrop ? "static" : true}
        backdropStyle={{ background: "rgba(39, 45, 55, 0.8)" }}
        dialogClassName={`h-auto ts-hide-scroll-but-keep-working bg-white ${
          !forPWA && `rounded-3xl ${noPadding ? "" : "p-6 pt-10"}`
        } flex flex-col justify-start items-center ${className}`}
      >
        {children}
      </Modal>
    ) : (
      <SwipeableBottomSheet
        isOpen={isOpen}
        onChange={onClose}
        isFullScreen={forPWA}
        swipeableViewsProps={{
          // animateTransitions: true,
          // resistance: true,
          disabled: forPWA,
        }}
        containerClassName={`${isOpen ? "ts-bottom-modal" : isTime ? "z-0" : "ts-bottom-modal"}`}
        bodyClassName={`ts-modal-shadow ts-hide-scroll-but-keep-working bg-white ${!forPWA && "rounded-t-3xl"} ${
          noPadding ? "" : "px-6 max-2xs:px-3"
        } pb-10 pt-2 relative flex flex-col justify-start items-center ${className}`}
      >
        <div
          className={`w-full ${
            forPWA ? "h-full" : "ts-bottom-modal-height"
          } overflow-auto ts-hide-scroll-but-keep-working ${noPadding || carModal ? "" : "pt-11"}`}
        >
          {!forPWA && (
            <div
              style={{ width: "102px" }}
              className="h-1 z-30 bg-white fixed top-2 left-0 right-0 mx-auto rounded-full ts-bg-gray-04 cursor-pointer"
            ></div>
          )}
          {children}
        </div>
      </SwipeableBottomSheet>
    );
  },
);

export { BottomModal };

const styles = {};
