/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { CustomProvider } from "rsuite";
import { QueryParamProvider } from "use-query-params";
import { AuthProvider } from "./auth";
import { ConfigLink, vState } from "./core";
import { AppContext, AppProvider } from "./core/contexts/context.app";
import { ConfigFetcher, Guard } from "./ui/components";
import { LoadingCustom } from "./ui/components/LoadingPage";

const env = import.meta.env;
//================= [Solo Pages] =================
//Layouts
const PanelLayout = React.lazy(() => import("./ui/_layouts/PanelLayout"));
const IntroLayout = React.lazy(() => import("./ui/_layouts/IntroLayout"));

//Solo pages
const Storybook = React.lazy(() => import("./ui/views/story/StoryBook"));
const Error404 = React.lazy(() => import("./ui/views/404/Error404"));

const App = () => {
  const appContext = useContext(AppContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    // if (env.REACT_APP_SHOW_DEBUG_FACILITIES === "1")
    //   if (!document.body.classList.contains("debug-screens")) document.body.classList.add("debug-screens");
    vState.isMobileDevice = isMobile;

    // ENABLE/DISABLE Console Logs
    // if (import.meta.env.PROD) {
    //   console.log = function () {};
    // }
  }, []);

  useEffect(() => {
    vState.isMobileDevice = isMobile;
  }, [isMobile]);

  return (
    <AuthProvider>
      <AppProvider>
        <CustomProvider>
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <React.Suspense fallback={<LoadingCustom />}>
                <ConfigFetcher>
                  <Guard forceNotLargeScreen>
                    <Switch>
                      <Route path={ConfigLink.homePage} exact>
                        <Guard forceUnauthenticated>
                          <IntroLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.reminders} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.reminderSingleDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.reminderDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.serviceProviders} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.serviceProviderDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.serviceProviderFeedbacks} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.serviceProviderLocation} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.serviceProvidersOnMap} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.registeredPlates} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.plateDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.plateHistory} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.plateReminders} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.earlyRegisters} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.shop} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.myPartDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.usersList} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.userDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.reports} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.feedbacks} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.featuresReport} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.versions} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.featuresManagement} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.businessTypes} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.serviceProvidersActivity} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.partRequests} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.partRequestDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.partCategories} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.carBrandAndTypes} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.brandDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.ownerSettings} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.bulkSmsList} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.bulkSmsDetail} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>
                      <Route path={ConfigLink.cities} exact>
                        <Guard forceAuthenticated>
                          <PanelLayout />
                        </Guard>
                      </Route>

                      <Route path={ConfigLink.storybook} component={Storybook} exact />
                      <Redirect from="/signout" to={ConfigLink.homePage} exact />
                      <Route component={Error404} />
                    </Switch>
                  </Guard>
                </ConfigFetcher>
              </React.Suspense>
            </QueryParamProvider>
          </Router>
        </CustomProvider>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;
