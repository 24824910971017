import { proxy } from "valtio";

interface ICategory {
  id: string;
  name: string;
  alias: string | null;
  features?: string[];
}

interface ISelectObj {
  label: string;
  value: string;
}

interface UserProfile {
  id: string;
  name: string;
  username: string;
  phoneNumber?: string;
  roles: string[];
  groups: string[];
  premium?: any | null;
  userProfileId: string;
  avatar?: string;
  refererId?: string;
  mobile: string;
}

export interface IVState {
  pageLoading: boolean;
  hasError: boolean;
  isMobileDevice: boolean;
  isScrolling: boolean;
  isEndOfPage: boolean;
  isAuthenticated: boolean;
  services: any[];
  plates: any[];
  cities: any[];
  categories: ICategory[];
  SelectPickerCategories: ISelectObj[];
  userInfo: UserProfile;
  superAdmin: boolean;
}

const vState = proxy({
  pageLoading: true,
  hasError: false,
  isMobileDevice: false,
  isScrolling: false,
  isEndOfPage: false,

  services: [],
  categories: [],
  cities: [],
  SelectPickerCategories: [],
  plates: [],
  isAuthenticated: false,
  userInfo: {
    id: "",
    name: "",
    username: "",
    phoneNumber: "",
    roles: [],
    groups: [],
    premium: null,
    mobile: "",
    userProfileId: "",
    avatar: "",
  },
  superAdmin: false,
} as IVState);

export { vState };
