/* eslint-disable */
import { axios } from "./api";

interface IRelateService {
  service: string;
  kilometerRatio: number;
}

const baseUrl = "/services";

const reminders = {
  addReminder: async (data: { name: string; expiresInDays?: number; expiresInKilometer?: number }) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, { ...data, disabled: false });

    return result;
  },
  editReminder: async (
    id,
    data: {
      name: string;
      description?: string;
      expiresInDays?: number;
      expiresInKilometer?: number;
      disabled?: boolean;
    },
  ) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, { ...data });

    return result;
  },

  getRelatedServices: async (id) => {
    const url = `ui/${baseUrl}/${id}/recommendations`;
    const result = await axios.get(url);

    return result;
  },
  addRelatedServices: async (id: string, items: IRelateService[] | null) => {
    const url = `${baseUrl}/${id}/recommendations`;
    const result = await axios.post(url, { items });

    return result;
  },
};

export { reminders };
