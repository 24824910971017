/* eslint-disable */

const ConfigLink = {
  setParam: (url: string, paramName: string, paramValue: string) => {
    return url.replace(paramName, paramValue);
  },
  //Storybook
  storybook: "/storybook",
  //Page Not Found
  pageNotFound: "/page-not-found",

  //dashboard
  homePage: "/",

  reminders: "/reminders",
  reminderSingleDetail: "/reminders/single-detail",
  reminderDetail: "/reminders/detail",

  serviceProviders: "/service-providers/list",
  serviceProvidersOnMap: "/service-providers/map",
  serviceProviderDetail: "/service-providers/list/detail",
  serviceProviderFeedbacks: "/service-providers/list/detail/feedbacks",
  serviceProviderLocation: "/service-providers/list/detail/location",
  serviceProvidersActivity: "/service-providers/activity",

  registeredPlates: "/plates",
  plateDetail: "/plates/detail",
  plateReminders: "/plates/reminders",
  plateHistory: "/plates/history",

  earlyRegisters: "/early-registers",

  usersList: "/users",
  userDetail: "/users/detail",

  reports: "/reports",
  feedbacks: "/feedbacks",

  featuresReport: "/features-report",

  versions: "/versions",

  featuresManagement: "/settings/feature-management",
  businessTypes: "/settings/business-types",
  packageManagement: "/settings/package-management",
  ownerSettings: "/settings/owner",

  partRequests: "/part-requests",
  partRequestDetail: "/part-requests/detail",
  partCategories: "/parts/categories",

  shop: "/shop",
  myPartDetail: "/shop/part-detail",

  carBrandAndTypes: "/cars-type",
  brandDetail: "/cars-type/detail",

  cities: "/cities",

  bulkSmsList: "/bulk-sms",
  bulkSmsDetail: "/bulk-sms/detail",
};
export { ConfigLink };
