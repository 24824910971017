import { account } from "./account";
import { bulkSms } from "./bulkSms";
import { car } from "./car";
import { common } from "./common";
import { map } from "./map";
import { partSupply } from "./partSupply";
import { plates } from "./plates";
import { reminders } from "./reminders";
import { shop } from "./shop";

const ApiInstance = {
  reminders,
  car,
  common,
  plates,
  account,
  partSupply,
  shop,
  map,
  bulkSms,
};

export { ApiInstance };
