//@ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useSnapshot } from "valtio";
import { vState, ConfigLink, Api, ServiceStorage } from "../../../core";
import { useHistory } from "react-router-dom";
import { TiPlus } from "react-icons/ti";
import { Button, Text } from "../../components";
import { HiHome, HiUserCircle, HiQrcode } from "react-icons/hi";
import srcImg from "../../../assets/images/Vector.svg";

interface IFooterButtonProps {
  isActive: boolean;
  icon: any;
  title: string;
  onClick: () => void;
}

const FooterButton: React.FC<IFooterButtonProps> = React.memo(({ icon, title, isActive = false, onClick }) => {
  return (
    <div onClick={onClick} className={`ts-footer-btn ${isActive && "ts-bg-primary-light"}`}>
      {icon}
      <Text type="HM" className={`${isActive ? "ts-primary" : "ts-accent"} font-bold`}>
        {title}
      </Text>
    </div>
  );
});

export { FooterButton };

const styles = {};
