/* eslint-disable */
import React, { useState, useEffect } from "react";

const DIR_TYPE = {
  start: "start",
  end: "end",
  center: "center",
  between: "between",
} as const;
type DIR_TYPE = typeof DIR_TYPE[keyof typeof DIR_TYPE];

interface IFlexRowProps {
  direction?: DIR_TYPE;
  className?: string;
}

const FlexRow: React.FC<IFlexRowProps> = React.memo(({ direction = "start", className, children }) => {
  return (
    <div
      className={`w-full flex flex-row ${
        direction === "start"
          ? "justify-start"
          : direction === "end"
          ? "justify-end"
          : direction === "between"
          ? "justify-between"
          : "justify-center"
      } ${className ? className : ""}`}
    >
      {children}
    </div>
  );
});

export { FlexRow };

const styles = {};
