/* eslint-disable */
import React from "react";
import { FiCornerUpRight } from "react-icons/fi";
import { Text } from "../";

interface IBackButtonProps {
  level: number;
  onClick: () => void;
}

const BackButton: React.FC<IBackButtonProps> = React.memo(({ level, onClick }) => {
  return (
    <>
      <div onClick={onClick} className="w-full flex justify-start items-center cursor-pointer">
        <FiCornerUpRight size={23} className="ts-accent ml-2" />
        <Text type="P4" className="ts-accent">
          بازگشت به {level === 2 ? "برندها" : "مدل ها"}
        </Text>
      </div>
      <hr className="w-full h-px border-none ts-bg-gray-02 my-4" />
    </>
  );
});

export { BackButton };

const styles = {};
