//@ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { vState } from "../../core";

interface IContainerScrollProps {
  heightOffset?: number | undefined;
  noPadding?: boolean;
  id?: string;
  children?: any;
  disableScroll?: boolean;
  className?: string;
  onScroll?: (e) => void;
}

const ContainerScroll = React.forwardRef(
  (
    {
      className = "",
      children,
      heightOffset = undefined,
      noPadding = false,
      id = undefined,
      disableScroll = false,
      onScroll,
    }: IContainerScrollProps,
    ref,
  ) => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const isMobile = useMediaQuery({
      query: "(max-width: 767px)",
    });

    const mobileHeight = useMediaQuery({
      query: "(max-height: 920px)",
    });
    useEffect(() => {
      vState.isScrolling = false;
    }, []);

    function navbarControlHandler(e) {
      let st = e.target.scrollingElement.scrollTop;

      if (st <= 20) {
        vState.isScrolling = false;
      }

      if (st > lastScrollTop && st > 20) {
        // downScroll code
        if (!vState.isScrolling) {
          vState.isScrolling = true;
        }
      } else {
        // upScroll code
        if (st <= 10) {
          if (vState.isScrolling) {
            vState.isScrolling = false;
          }
        }
      }
      setLastScrollTop(st <= 0 ? 0 : st); // For Mobile or negative scrolling

      if (window.innerHeight + window.scrollY >= document.body.scrollHeight - 1.5) {
        console.log("REACHED END OF PAGE");
        vState.isEndOfPage = true;
      }
    }

    useEffect(() => {
      window.addEventListener("scroll", navbarControlHandler);
    }, []);

    return (
      <div
        // onScroll={controlNavbar}
        style={{ position: "unset", height: "inherit", paddingBottom: heightOffset, paddingTop: "88px" }}
        className={`w-full overflow-x-hidden overflow-y-auto ts-hide-scroll-but-keep-working z-30 ${className} max-2xs:px-3 px-6`}
      >
        {children}
      </div>
    );
  },
);

export { ContainerScroll };
