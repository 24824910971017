/* eslint-disable */
import qs from "qs";
import { axios } from "./api";

const baseUrl = "/bulksms";

interface IInfo {
  payload: string;
  contactIds: string[];
}

const bulkSms = {
  getHistory: async (page: number, sort: "DATE" | "NAME" | null) => {
    const url = `${baseUrl}`;
    const result = await axios.get(url, {
      params: {
        pageSize: 60,
        page,
        ...(sort ? { sort } : undefined),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return result;
  },
  getHistoryDetail: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  changeBulkStatus: async (id: string, status: "approved" | "rejected", reason?: string) => {
    const url = `${baseUrl}/${id}/changeStatus`;
    const result = await axios.put(url, { status });

    return result;
  },
};

export { bulkSms };
