/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { useSnapshot } from "valtio";
import { vState } from "../../../core";
import { Text } from "../../components";

interface IAccordionMenuProps {
  menuItems: any[];
  title: string;
}

const AccordionMenu: React.FC<IAccordionMenuProps> = React.memo(({ menuItems, title = "" }) => {
  const snap = useSnapshot(vState);
  const history = useHistory();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const urls = menuItems.findIndex((item) => item.url === window.location.pathname);
    if (urls !== -1) setOpen(true);
  }, [menuItems]);

  return (
    <div
      className={`w-full transition-all ${open ? "ts-open-menu-item bg-white" : "ts-close-menu-item"} p-6 ts-gray-08
                  } cursor-pointer`}
    >
      <div className="flex mb-6 justify-start items-start">
        <Text onClick={() => setOpen((prev) => !prev)} type="H5" className={`cursor-pointer`}>
          {title}
        </Text>
        <HiChevronDown size={22} className={`ts-accent mr-2 ${open ? "ts-arrow-up" : "ts-arrow-down"}`} />
      </div>
      {menuItems.map((item: any) => {
        return (
          <Text
            onClick={() => history.push(item.url)}
            type="H6"
            className={`w-full mb-4 mr-6 ${
              window.location.pathname === item.url ? "ts-primary" : "ts-gray-08"
            } cursor-pointer`}
          >
            {item.label}
          </Text>
        );
      })}
    </div>
  );
});

export { AccordionMenu };

const styles = {};
