/* eslint-disable */
import React, { useState, useEffect } from "react";

const FLEX_COL_TYPE = {
  1: "flex-1",
  2: "flex-2",
  3: "flex-3",
  4: "flex-4",
  5: "flex-5",
  6: "flex-6",
  7: "flex-7",
  8: "flex-8",
  9: "flex-9",
  10: "flex-10",
  11: "flex-11",
  12: "flex-12",
} as const;
type FLEX_COL_TYPE = typeof FLEX_COL_TYPE[keyof typeof FLEX_COL_TYPE];

interface IFlexGridProps {
  className?: string;
}

interface IFlexGridRowProps {
  className?: string;
}

interface IFlexGridColProps {
  className?: string;
  flex?: FLEX_COL_TYPE;
}

const FlexGrid: React.FC<IFlexGridProps> = React.memo(({ children, className = "" }) => {
  return <div className={`ts-flex-grid w-full ${className}`}>{children}</div>;
});
const FlexGridRow: React.FC<IFlexGridRowProps> = React.memo(({ children, className = "" }) => {
  return (
    <div className={`ts-flex-grid-row w-full flex flex-row justify-start items-start ${className}`}>{children}</div>
  );
});

const FlexGridCol: React.FC<IFlexGridColProps> = React.memo(({ children, flex = "flex-1", className = "" }) => {
  return <div className={`flex flex-col justify-start items-start ts-${flex} ${className}`}>{children}</div>;
});

export { FlexGrid, FlexGridRow, FlexGridCol };

const styles = {};
