import React from "react";
import { ConfigLink } from "./configLinks";

//panel
const reminders = React.lazy(() => import("../../ui/views/Panel/Reminders"));
const reminderSingleDetail = React.lazy(() => import("../../ui/views/Panel/ReminderSingle"));
const reminderDetail = React.lazy(() => import("../../ui/views/Panel/ReminderDetail"));

const serviceProviders = React.lazy(() => import("../../ui/views/Panel/ServiceProviders"));
const serviceProviderDetail = React.lazy(() => import("../../ui/views/Panel/ServiceProviderDetail"));
const serviceProviderFeedbacks = React.lazy(() => import("../../ui/views/Panel/ServiceProviderFeedbacks"));
const serviceProviderLocation = React.lazy(() => import("../../ui/views/Panel/ViewLocation"));
const serviceProviderActivity = React.lazy(() => import("../../ui/views/Panel/ServiceProvidersActivity"));
const serviceProvidersOnMap = React.lazy(() => import("../../ui/views/Panel/ServiceProvidersOnMap"));

const registeredPlates = React.lazy(() => import("../../ui/views/Panel/Plates"));
const plateDetail = React.lazy(() => import("../../ui/views/Panel/PlateDetail"));
const plateReminders = React.lazy(() => import("../../ui/views/Panel/plateReminders"));
const plateHistory = React.lazy(() => import("../../ui/views/Panel/plateHistory"));

const earlyRegisters = React.lazy(() => import("../../ui/views/Panel/EarlyRegisters"));

const usersList = React.lazy(() => import("../../ui/views/Panel/Users"));
const userDetail = React.lazy(() => import("../../ui/views/Panel/UserDetail"));

const introduction = React.lazy(() => import("../../ui/views/Introductions/Introduction"));

const reports = React.lazy(() => import("../../ui/views/Panel/Reports"));
const feedbacks = React.lazy(() => import("../../ui/views/Panel/Feedbacks"));

const featuresReport = React.lazy(() => import("../../ui/views/Panel/FeaturesReport"));

const versions = React.lazy(() => import("../../ui/views/Panel/Versions"));

const featuresManagement = React.lazy(() => import("../../ui/views/Panel/FeatureManagement"));
const businessTypes = React.lazy(() => import("../../ui/views/Panel/BusinessTypes"));
const ownerSettings = React.lazy(() => import("../../ui/views/Panel/OwnerSettings"));

const partRequests = React.lazy(() => import("../../ui/views/Panel/PartRequests"));
const partRequestDetail = React.lazy(() => import("../../ui/views/Panel/PartRequestDetail"));
const partCategories = React.lazy(() => import("../../ui/views/Panel/PartCategories"));

const shop = React.lazy(() => import("../../ui/views/Panel/MyShop"));
const myPartDetail = React.lazy(() => import("../../ui/views/Panel/MyPartDetail"));

const carBrandAndTypes = React.lazy(() => import("../../ui/views/Panel/CarBrandTypes"));
const brandDetail = React.lazy(() => import("../../ui/views/Panel/CarBrandDetail"));

const bulkSmsList = React.lazy(() => import("../../ui/views/Panel/ProvidersBulkSms"));
const bulkSmsDetail = React.lazy(() => import("../../ui/views/Panel/ProvidersBulkSmsDetail"));

const cities = React.lazy(() => import("../../ui/views/Panel/CitiesList"));

//================== panel routes
export const routes_panel = [
  {
    path: ConfigLink.reminders,
    component: reminders,
  },
  {
    path: ConfigLink.reminderSingleDetail,
    component: reminderSingleDetail,
  },
  {
    path: ConfigLink.reminderDetail,
    component: reminderDetail,
  },
  {
    path: ConfigLink.serviceProviders,
    component: serviceProviders,
  },
  {
    path: ConfigLink.serviceProviderDetail,
    component: serviceProviderDetail,
  },
  {
    path: ConfigLink.serviceProviderFeedbacks,
    component: serviceProviderFeedbacks,
  },
  {
    path: ConfigLink.serviceProviderLocation,
    component: serviceProviderLocation,
  },
  {
    path: ConfigLink.serviceProvidersOnMap,
    component: serviceProvidersOnMap,
  },
  {
    path: ConfigLink.registeredPlates,
    component: registeredPlates,
  },
  {
    path: ConfigLink.plateDetail,
    component: plateDetail,
  },
  {
    path: ConfigLink.plateHistory,
    component: plateHistory,
  },
  {
    path: ConfigLink.plateReminders,
    component: plateReminders,
  },
  {
    path: ConfigLink.earlyRegisters,
    component: earlyRegisters,
  },
  {
    path: ConfigLink.shop,
    component: shop,
  },
  {
    path: ConfigLink.myPartDetail,
    component: myPartDetail,
  },
  {
    path: ConfigLink.usersList,
    component: usersList,
  },
  {
    path: ConfigLink.userDetail,
    component: userDetail,
  },
  {
    path: ConfigLink.reports,
    component: reports,
  },
  {
    path: ConfigLink.feedbacks,
    component: feedbacks,
  },
  {
    path: ConfigLink.featuresReport,
    component: featuresReport,
  },
  {
    path: ConfigLink.versions,
    component: versions,
  },
  {
    path: ConfigLink.featuresManagement,
    component: featuresManagement,
  },
  {
    path: ConfigLink.businessTypes,
    component: businessTypes,
  },
  {
    path: ConfigLink.ownerSettings,
    component: ownerSettings,
  },
  {
    path: ConfigLink.serviceProvidersActivity,
    component: serviceProviderActivity,
  },
  {
    path: ConfigLink.partRequests,
    component: partRequests,
  },
  {
    path: ConfigLink.partRequestDetail,
    component: partRequestDetail,
  },
  {
    path: ConfigLink.partCategories,
    component: partCategories,
  },
  {
    path: ConfigLink.carBrandAndTypes,
    component: carBrandAndTypes,
  },
  {
    path: ConfigLink.brandDetail,
    component: brandDetail,
  },
  {
    path: ConfigLink.bulkSmsList,
    component: bulkSmsList,
  },
  {
    path: ConfigLink.bulkSmsDetail,
    component: bulkSmsDetail,
  },
  {
    path: ConfigLink.cities,
    component: cities,
  },
];

//================== login routes
export const routes_login = [
  {
    path: ConfigLink.homePage,
    component: introduction,
  },
];
