/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import EmptyStateImage from "../../assets/images/welcome-image-3.svg";
import { ConfigLink, ServiceStorage } from "../../core";
import { Button } from "./Buttons";
import { Image } from "./Image";
import { Text } from "./Text";

interface INoWorkspaceEmptyStateProps {
  returnUrl: string;
}

const NoWorkspaceEmptyState: React.FC<INoWorkspaceEmptyStateProps> = React.memo(({ returnUrl = "homePage" }) => {
  const history = useHistory();

  useEffect(() => {}, []);

  const addWorkshopHandler = () => {
    ServiceStorage.setTempReturnURL(returnUrl);
    // history.push(ConfigLink.createWorkspaceStepOne);
  };

  return (
    <div className="w-full mt-28 max-xs:mt-8 ts-shadow rounded-xl p-6 bg-white flex flex-col justify-center items-center ts-low-height">
      {/* <Image src={EmptyStateImage} className="mb-8" /> */}
      <Text type="P3" className="ts-gray-08 ts-letter-space text-center">
        برای ثبت پلاک و ایجاد یادآور نیاز به تکمیل پروفایل خود دارید.
      </Text>
      <Button
        bcolor="primary"
        textType="H6"
        classNameContainer="w-full"
        className="w-full h-12 rounded-xl ts-letter-space mt-7"
        onClick={addWorkshopHandler}
      >
        اضافه کردن تعمیرگاه
      </Button>
    </div>
  );
});

export { NoWorkspaceEmptyState };

const styles = {};
