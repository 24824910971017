import React, { useState, useContext, useMemo } from "react";
import { ServiceStorage } from "../services/service.storage";

// Create Context Object [schema]
const AppContext = React.createContext({
  isAuth: false,
  setIsAuth: (val: boolean) => {},
});

// Create AppProvider to use AppContext object
const AppProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);

  // context value object as store
  const value = useMemo(
    () => ({
      isAuth,
      setIsAuth: (val) => {
        setIsAuth(val);
      },
    }),
    [isAuth],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

//--- Readily AppContextHooks
const useAuth = () => {
  const { isAuth, setIsAuth } = useContext(AppContext);
  return { isAuth, setIsAuth };
};
//---

export { AppProvider, AppContext, useAuth };
