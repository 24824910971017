import React from "react";
console.warn = console.error = () => {}; //Disable warnings and errors
import ReactDOM from "react-dom";
import { ConfigApp } from "./core/config/config.app";
import "./assets/less/rsuite.less";
import "./assets/scss/app.scss";
import App from "./App";
import { vState } from "./core";

//---- 100 Vh for mobile responsive mode. we set it in --vh variable, see _functions.scss file
function setDocHeight() {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight / 100}px`);
  document.documentElement.style.setProperty("--rs-state-focus-shadow", `none`); //Disabling box-shadow in rsuite
}

window.addEventListener("resize", setDocHeight);
window.addEventListener("orientationchange", setDocHeight);
setDocHeight();

// //---- fix IOS devices zoom on fields
const disableIosTextFieldZoom = () => {
  const el = document.querySelector("meta[name=viewport]");
  if (el !== null) {
    let content = el.getAttribute("content");
    let re = /maximum\-scale=[0-9\.]+/g;
    //@ts-ignore
    if (re.test(content)) content = content.replace(re, "maximum-scale=1.0");
    else content = [content, "maximum-scale=1.0", "user-scalable=0"].join(", ");
    el.setAttribute("content", content);
  }
};
//@ts-ignore
const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
if (checkIsIOS()) disableIosTextFieldZoom();

// //--- App configs
// ConfigApp.enableGoogleAnalytics();
// ConfigApp.enableHotjar();
ConfigApp.initKeycloak();

ReactDOM.render(<App />, document.getElementById("root"));
