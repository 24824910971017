// @ts-nocheck
/* eslint-disable */
import React from "react";
import { BlockLoading, DisappearedLoading } from "react-loadingg";

//Images:
import { SuspenseLoading } from "ui/_layouts/components";

const LoadingPage = React.memo(() => {
  return <DisappearedLoading color="#752FBB" size="Large" />;
});

const LoadingBlock = React.memo(() => {
  return <BlockLoading size="Large" />;
});

const LoadingCustom = React.memo(() => {
  return <SuspenseLoading />;
});

export { LoadingPage, LoadingBlock, LoadingCustom };
