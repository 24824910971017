/* eslint-disable */
import React, { useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { Rating, RatingProps } from "react-simple-star-rating";

interface IStarRatingProps extends RatingProps {
  initialValue: number;
  size: number;
  allowFraction?: boolean;
  readonly?: boolean;
  onClick?: () => void;
}

const StarRating: React.FC<IStarRatingProps> = React.memo(
  ({ initialValue, size, allowFraction = true, readonly = true, onClick, ...restProps }) => {
    useEffect(() => {}, []);
    return (
      <Rating
        {...restProps}
        initialValue={initialValue}
        size={size}
        fillIcon={<FaStar size={size} className="mx-0.5 inline-block" />}
        emptyIcon={<FaStar size={size} className="mx-0.5 inline-block" />}
        allowFraction={allowFraction}
        readonly={readonly}
        transition
        fillColor="#FFA23A"
        emptyColor="#D5DDE5"
        emptyClassName="inline-block"
        SVGclassName="inline-block mb-2.5"
      />
    );
  },
);

export { StarRating };

const styles = {};
