/* eslint-disable */
/* @ts-ignore */
import React, { useState, useEffect, ComponentType } from "react";
import { Form, InputGroup, FormControlProps, FormProps } from "rsuite";
import { HiExclamationCircle, HiEye, HiEyeOff, HiCheckCircle } from "react-icons/hi";
import { Text } from "./";

const SIZE_TYPE = {
  lg: "lg",
  md: "md",
} as const;
type SIZE_TYPE = typeof SIZE_TYPE[keyof typeof SIZE_TYPE];

const INPUT_TYPE = {
  text: "text",
  password: "password",
  number: "number",
} as const;
type INPUT_TYPE = typeof INPUT_TYPE[keyof typeof INPUT_TYPE];

interface IInputFieldProps extends FormProps {
  label?: any;
  valid?: boolean;
  error?: boolean;
  showHint?: boolean;
  fluid?: boolean;
  size?: SIZE_TYPE;
  placeholder?: string;
  hint?: string;
  name?: string;
  className?: string;
  classNameContainer?: string;
  width?: string;
  type?: INPUT_TYPE;
  addonStaticIconComponent?: any;
  addonButtonIconComponent?: any;
  value: any;
  withoutAddon?: boolean;
  onChange?: (e?: any) => void;
}

const InputField: React.FC<IInputFieldProps> = React.memo(
  ({
    label,
    valid = false,
    error = false,
    showHint = true,
    withoutAddon = false,
    fluid = false,
    type = "text",
    size = "md",
    placeholder = "",
    hint,
    name = "name",
    addonStaticIconComponent,
    addonButtonIconComponent,
    className,
    width,
    value,
    classNameContainer,
    ...restProps
  }) => {
    const [showPassword, setShowPassword] = useState(type === "password");
    useEffect(() => {}, []);
    valid = valid && value;
    error = error && value;
    showHint = showHint && value;

    let CustomAddOn =
      addonStaticIconComponent &&
      React.cloneElement(addonStaticIconComponent, {
        className: `addon-normal ${addonStaticIconComponent.props?.className}`,
      });
    let CustomAddOnButton =
      addonButtonIconComponent &&
      React.cloneElement(addonButtonIconComponent, {
        className: `addon-normal ${addonButtonIconComponent.props?.className}`,
      });

    const addonContent = () => {
      return (
        <>
          {type === "password" && (
            <>
              <InputGroup.Addon className="mx-6 mt-px">
                {error && <HiExclamationCircle size={19} className={`${"addon-error mt-3"}`} />}
                {valid && <HiCheckCircle size={19} className={`${"addon-valid mt-3"}`} />}
              </InputGroup.Addon>
              <InputGroup.Button
                tabIndex={-1}
                appearance="ghost"
                dir="rtl"
                className="bg-transparent flex justify-center items-center w-4 h-12 pl-2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword && <HiEye size={18} className={`addon-normal ${"-mr-3"}`} />}
                {showPassword && <HiEyeOff size={18} className={`addon-normal ${" -mr-3"}`} />}
              </InputGroup.Button>
            </>
          )}
          {type !== "password" && (error || valid) && (
            <InputGroup.Addon className="-mr-1">
              {error && <HiExclamationCircle className={`${"addon-error mt-2"}`} />}
              {valid && !error && <HiCheckCircle className={`${"addon-valid mt-2"}`} />}
            </InputGroup.Addon>
          )}
          {addonStaticIconComponent && (
            <InputGroup.Addon className="">
              {/* @ts-ignore */}
              {CustomAddOn}
            </InputGroup.Addon>
          )}
          {addonButtonIconComponent && (
            <InputGroup.Button className="">
              {/* @ts-ignore */}
              {CustomAddOnButton}
            </InputGroup.Button>
          )}
        </>
      );
    };

    return (
      <Form.Group className={`ts-input-field ${classNameContainer}`}>
        {label && <Form.ControlLabel>{label}</Form.ControlLabel>}
        {!withoutAddon ? (
          <InputGroup
            className="ts-input-group"
            inside
            size={size}
            style={{ width: fluid ? "calc(100%)" : `${width}`, border: "none" }}
          >
            {addonContent()}
            {/* @ts-ignore */}
            <Form.Control
              size={size}
              name={name}
              placeholder={placeholder}
              type={!showPassword ? "text" : type}
              className={`${
                valid && !error ? "border-valid" : error ? "border-error" : "border-normal"
              } ${className} rounded-xl h-12 pt-1.5`}
              value={value}
              {...restProps}
            />
          </InputGroup>
        ) : (
          <InputGroup
            className="ts-input-group"
            inside
            size={size}
            style={{ width: fluid ? "calc(100%)" : `${width}`, border: "none", direction: "rtl" }}
          >
            {/* @ts-ignore */}
            <Form.Control
              size={size}
              name={name}
              placeholder={placeholder}
              type={!showPassword ? "text" : type}
              style={{ background: "#0a0" }}
              className={`${
                valid && !error ? "border-valid" : error ? "border-error" : "border-normal"
              } ${className} rounded-xl `}
              value={value}
              {...restProps}
            />
          </InputGroup>
        )}

        {hint && showHint && (
          <Form.HelpText>
            <Text type="P5" className={`ts-error ${""}`}>
              {hint}
            </Text>
          </Form.HelpText>
        )}
      </Form.Group>
    );
  },
);

export { InputField };

const styles = {};
