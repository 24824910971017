/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../core";
import { axios } from "../../../core/api/api";
import { ServerNotRespond } from "../../views/404/ServerNotRespond";
import { LoadingCustom } from "../LoadingPage";

interface IConfigFetcherProps {
  children: any;
}

const ConfigFetcher: React.FC<IConfigFetcherProps> = React.memo(({ children }) => {
  const history = useHistory();
  const snap = useSnapshot(vState);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    healthCheck();
  }, []);

  const getUI = async () => {
    await ApiInstance.common.getUI();
  };

  const healthCheck = async () => {
    await axios
      .get("/ping")
      .then((res) => {
        console.log("HealthCheck", res);
        vState.hasError = false;
        setIsFetched(true);
        getUI();
      })
      .catch((e) => {
        console.log("error", e);
        vState.hasError = true;
      });
  };

  //return children;
  return !snap.hasError ? isFetched ? children : <LoadingCustom /> : <ServerNotRespond />;
});

export { ConfigFetcher };

const styles = {};
