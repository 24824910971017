/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";

interface ICarsLoaderProps {}

const CarsLoader: React.FC<ICarsLoaderProps> = React.memo(() => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
        return (
          <>
            <div key={item} className="w-full flex justify-between items-center cursor-pointer">
              <div className="flex justify-center items-center">
                {/* <Skeleton className="w-6 h-6 ml-2" /> */}
                <Skeleton className="w-12 h-5" />
              </div>
              <Skeleton className="w-6 h-6 ml-2" />
            </div>
            {item !== 10 && <hr className="w-full h-px border-none ts-bg-gray-02 mt-4 mb-4" />}
          </>
        );
      })}
    </>
  );
});

export { CarsLoader };

const styles = {};
