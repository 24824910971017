import { IConfigurations } from "./types";

export const stageConfigs: IConfigurations = {
  endpointUrl: "https://admin.designersonline.ir/api",
  imageUrl: "https://virazh-dev.s3.ir-thr-at1.arvanstorage.ir",
  keycloakClient: "app",
  keycloakRealm: "virazh-admin",
  keycloakUrl: "https://auth.designersonline.ir/",
  logoType: "virazh",
  mapApi: "web.7c2441e3ee1446169b234a949c3d2851",
  locationToAddressApi: "service.53e5f71c41fe4b03b37d3abc0c15d6c7",
  showDebugFacilities: "1",
};
