/* eslint-disable */
import React from "react";
import { BiChevronLeft, BiSquareRounded } from "react-icons/bi";
import { IoCheckbox } from "react-icons/io5";
import { Text } from "../";

interface ICarsLevelWrapperProps {
  level: number;
  hasLogo?: boolean;
  data: any;
  onClick: () => void;
  selectedItem: any;
  mainList: any[];
  selectedCar: any;
  index: number;
}

const CarsLevelWrapper: React.FC<ICarsLevelWrapperProps> = React.memo(
  ({ level, hasLogo = false, data, onClick, selectedItem, selectedCar, index, mainList }) => {
    const childrenCondition = level === 1 ? data.children === 0 : level === 2 ? data.children.length === 0 : true;
    return (
      <>
        <div onClick={onClick} className="w-full flex justify-between items-center cursor-pointer">
          <div className="flex justify-center items-center">
            {childrenCondition ? (
              selectedItem?.id === data.id || selectedCar?.id === data.id ? (
                <IoCheckbox size={23} className="ts-primary" />
              ) : (
                <BiSquareRounded size={23} className="ts-gray-04" />
              )
            ) : null}
            {hasLogo && null /* <div className="w-6 h-6 bg-transparent"></div> */}
            <Text type="P4" className="ts-accent mr-2">
              {data.name}
            </Text>
          </div>
          {!childrenCondition && <BiChevronLeft size={26} className="ts-accent" />}
        </div>
        {index < mainList.length - 1 && <hr className="w-full h-px border-none ts-bg-gray-02 mt-4 mb-4" />}
      </>
    );
  },
);

export { CarsLevelWrapper };

const styles = {};
