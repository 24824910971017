/* eslint-disable */
import { UserProfile } from "#types";
import { axios } from "./api";

const account = {
  getUserProfile: async () => {
    const url = `/me`;
    const result = await axios.get(url);
    return result.data as UserProfile;
  },
};

export { account };
