import { useAuth } from "#hooks";
import { ConfigLink } from "core";
import { FC, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import LargeScreenWarn from "./configurations/LargeScreenWarn";
import UnauthorizedAccess from "./configurations/UnauthorizedAccess";
import { LoadingCustom } from "./LoadingPage";

interface GuardProps {
  forceAuthenticated?: boolean;
  forceUnauthenticated?: boolean;
  forceNotLargeScreen?: boolean;
  forceUserRole?: string;
}

export const Guard: FC<GuardProps> = ({
  children,
  forceAuthenticated,
  forceUnauthenticated,
  forceNotLargeScreen,
  forceUserRole,
}) => {
  // show loading if we need to wait for authentication service
  const [ready, setReady] = useState<boolean>(forceAuthenticated === undefined && forceUnauthenticated === undefined);
  const [showRoleError, setShowRoleError] = useState(false);
  const auth = useAuth();
  const history = useHistory();
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 920px)",
  });

  useEffect(() => {
    if (auth.ready) {
      setReady(true);

      if (!auth.authenticated && forceAuthenticated) {
        history.replace(ConfigLink.homePage);
      } else if (auth.authenticated && forceUnauthenticated) {
        history.replace(ConfigLink.reminders);
      }

      if (forceAuthenticated && forceUserRole && auth.authenticated && !auth.profile?.roles.includes(forceUserRole)) {
        console.log("Error access");
        setShowRoleError(true);

        setTimeout(() => {
          auth.logout();
        }, 2500);
      }
    }
  }, [auth.ready, auth.authenticated, forceUserRole]);

  // show loading if authentication result is not ready yet
  if (!ready) {
    return <LoadingCustom />;
  }

  // if (isLargeScreen && forceNotLargeScreen) {
  //   return <LargeScreenWarn />;
  // }

  if (showRoleError) {
    return <UnauthorizedAccess />;
  }

  return <>{children}</>;
};
