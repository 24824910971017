/* eslint-disable */
import { isArabic, toPersianChars } from "@persian-tools/persian-tools";
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { Form, Input, InputGroup, Loader } from "rsuite";
import { BottomModal, Text } from "ui/components";
import { useSnapshot } from "valtio";

interface ISelectCityProps {
  isOpen: boolean;
  onClose: () => void;
  selectedId?: string | null;
  onSelect: (id: string) => void;
}

const SelectCity: React.FC<ISelectCityProps> = React.memo(({ isOpen, onClose, selectedId, onSelect }) => {
  const snap = useSnapshot(vState);

  const [filteredCity, setFilteredCity] = useState<any[]>([]);
  const [prioritiesCities, setPrioritiesCities] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const setCities = (value) => {
    setFilteredCity(value);
    const priorities = value.filter((item) => item.priority === 1);
    setPrioritiesCities(priorities);
  };

  const getCities = async () => {
    await ApiInstance.common
      .getAllCities()
      .then((res) => {
        console.log(res);
        const newArrayOfObj = res.data.data.map(
          ({ id: value, city: city, province: province, priority: priority }) => ({
            value,
            label: `${isArabic(city) ? toPersianChars(city) : city}، ${
              isArabic(province) ? toPersianChars(province) : province
            }`,
            priority,
          }),
        );
        vState.cities = newArrayOfObj;
        setCities(newArrayOfObj);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      if (snap.cities.length === 0) {
        getCities();
      } else {
        setCities(snap.cities);
        setLoading(false);
      }
    }
  }, [isOpen]);

  const changeText = (e) => {
    setSearchText(e);
    if (e.length === 0) {
      setFilteredCity(snap.cities);
    } else {
      const result = snap.cities.filter((item) => item.label.includes(e));
      setFilteredCity(result);
    }
  };

  const resetSearch = () => {
    setSearchText("");
    setFilteredCity(snap.cities);
  };

  const selectCityHandler = (city) => {
    onSelect(city);
    onClose();
  };

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      noPadding
      modalClassName="ts-city-modal-lg"
      className="relative ts-city-modal-dialog"
    >
      <div
        className={`w-full flex flex-col justify-start items-start bg-white sticky top-0 z-20 px-6 right-0 pt-10 ${
          prioritiesCities.length === 0 ? "pb-6" : "pb-4"
        }`}
      >
        <Text type="H3" className="ts-accent mb-6">
          انتخاب شهر
        </Text>
        <Text type="P4" className="ts-gray-07 mb-4">
          شهر خود را جستجو نمایید.
        </Text>
        <Form fluid className={`w-full transition-all`}>
          <InputGroup inside className="w-full h-11 outline-none ts-input-border">
            <InputGroup.Button
              onClick={resetSearch}
              className={`${
                searchText.length === 0 ? "hidden" : ""
              } left-9 h-full bg-transparent hover:bg-transparent mr-7`}
            >
              <IoMdClose size={19} className={`transition-colors duration-200 -mt-1`} />
            </InputGroup.Button>
            <InputGroup.Button className="h-full left-0 bg-transparent hover:bg-transparent">
              <AiOutlineSearch size={23} className={`ts-gray-03 transition-colors duration-200 -mt-1`} />
            </InputGroup.Button>
            <Input
              value={searchText}
              onChange={(e) => changeText(e)}
              className="h-full outline-none ts-shadow border-none rounded-2xl placeholder:text-gray-200"
              placeholder="جستجو"
            />
          </InputGroup>
        </Form>
      </div>
      {loading ? (
        <>
          <div className="w-full flex justify-center items-center mt-16 z-10">
            <Loader size="sm" content="لطفا صبر کنید..." vertical />
          </div>
        </>
      ) : (
        <div className={`w-full flex flex-col justify-start items-start mt-1.5`}>
          {prioritiesCities.length !== 0 && searchText.length === 0 && (
            <>
              <Text type="P5" className="ts-gray-04 mb-4 mr-6">
                شهرهای پرتکرار
              </Text>

              {prioritiesCities.map((item) => {
                return (
                  <div
                    className={`w-full flex justify-start cursor-pointer items-center py-3 ${
                      selectedId === item.value && "ts-bg-primary-light"
                    }`}
                  >
                    <Text
                      onClick={() => selectCityHandler(item)}
                      key={item.value}
                      type={selectedId === item.value ? "H6" : "P4"}
                      className="ts-accent px-6"
                    >
                      {item.label}
                    </Text>
                  </div>
                );
              })}
              <div className="w-full my-3 px-6">
                <hr className="w-full border-gray-300" />
              </div>
            </>
          )}

          {filteredCity.map((item) => {
            return (
              <div
                className={`w-full flex justify-start cursor-pointer items-center py-3 ${
                  selectedId === item.value && "ts-bg-primary-light"
                }`}
              >
                <Text
                  onClick={() => selectCityHandler(item)}
                  key={item.value}
                  type={selectedId === item.value ? "H6" : "P4"}
                  className="ts-accent px-6"
                >
                  {item.label}
                </Text>
              </div>
            );
          })}
        </div>
      )}
    </BottomModal>
  );
});

export { SelectCity };

const styles = {};
