import { UserProfile } from "#types";
import { createContext, FC, PropsWithChildren, useLayoutEffect, useState } from "react";
import { ApiInstance, ServiceStorage, vState } from "./core";
import { keycloak } from "./keycloak";

export const AuthContext = createContext<{
  ready: boolean;
  authenticated: boolean;
  profile?: UserProfile;
}>({ authenticated: false, ready: false, profile: undefined });

export const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined);

  useLayoutEffect(() => {
    keycloak.onReady = (result) => {
      setAuthenticated(!!result);
      vState.isAuthenticated = !!result;
      if (!!result) {
        ApiInstance.account.getUserProfile().then((profile: any) => {
          console.log("profile", profile);
          ServiceStorage.setAuth(profile);
          vState.userInfo = profile;
          vState.superAdmin = profile.roles.includes("super-admin");
          setProfile(profile);
          setReady(true);
        });
      } else {
        setReady(true);
      }
    };

    // keycloak.onAuthSuccess = () => {
    //   ApiInstance.account.getUserProfile().then((profile) => {
    //     ServiceStorage.setAuth(profile);
    //     setProfile(profile);
    //   });
    // };

    keycloak.onAuthLogout = () => setAuthenticated(false);
    keycloak.onTokenExpired = () => {
      keycloak.updateToken(30);
      if (keycloak.idToken) {
        ServiceStorage.setToken(keycloak.idToken);
      }
    };
  }, []);

  return <AuthContext.Provider value={{ ready, authenticated, profile }}>{children}</AuthContext.Provider>;
};
