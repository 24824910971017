/* eslint-disable */
import { ReactNode } from "react";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdClose, MdError } from "react-icons/md";
import { Notification, toaster } from "rsuite";
import { Text } from "../../ui/components";

/**
 * Show notification message
 * @example:
 * const openNotification = useNotification();
 * openNotification("Something left wrong, try again.");
 */

type NotificationType = "info" | "success" | "warning" | "error";

const useNotification = (
  message: string | ReactNode,
  type: NotificationType,
  title?: string,
  duration: number = 4500,
) => {
  let icon;
  let defaultTitle = "";

  switch (type) {
    case "success":
      icon = <IoIosCheckmarkCircle size={23} className="ts-success -mt-1" />;
      defaultTitle = "موفق";
      break;
    case "info":
      icon = <IoIosCheckmarkCircle size={23} className="ts-info -mt-1 " />;
      defaultTitle = "اطلاع";
      break;
    case "warning":
      icon = <BsExclamationTriangleFill size={23} className="ts-warning -mt-1" />;
      defaultTitle = "هشدار";
      break;
    case "error":
      icon = <MdError size={23} className="ts-error -mt-1" />;
      defaultTitle = "اخطار";
      break;
  }
  return toaster.push(
    <Notification
      type={type}
      // header={
      //   <div className="w-full flex justify-between items-center">
      //     <Text type="H5" className={`ts-${type} mt-0.5 mr-2`}>
      //       {!title ? defaultTitle : title}
      //     </Text>
      //     <IoCloseCircle size={24} className="ts-gray-07" onClick={() => toaster.clear()} />
      //   </div>
      // }
      header={null}
      duration={duration}
      className={`ts-notification-mobile`}
      closable={false}
      style={{ width: "421px" }}
    >
      <div className="w-full flex justify-between items-center">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center ml-2 mr-2.5">
            <div
              className={`w-1 h-full fixed right-4 ts-notification-bar rounded-full ml-2 ${
                type === "success"
                  ? "ts-bg-success"
                  : type === "error"
                  ? "ts-bg-error"
                  : type === "warning"
                  ? "ts-bg-warning"
                  : "ts-bg-info"
              }`}
            ></div>
            {icon}
          </div>
          {typeof message === "string" ? (
            <Text type="P3" className="ts-gray-08">
              {message}
            </Text>
          ) : (
            message
          )}
        </div>
        <MdClose size={24} className="ts-gray-06 -mt-px" onClick={() => toaster.clear()} />
      </div>
    </Notification>,
    { placement: "topEnd", style: { zIndex: 99999 } },
  );
};

export { useNotification };
