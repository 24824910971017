/* eslint-disable */
import { axios } from "./api";

interface IPart {
  name: string;
  car: string;
  description?: string;
}

const baseUrl = `/parts`;

const partSupply = {
  getRequests: async (page: number, sort: "NON" | "DATE" | "NAME" | null, query: string | null) => {
    const url = `${baseUrl}?pageSize=50&page=${page}&sort=${sort}${query ? `&query=${query}` : ""}`;
    const result = await axios.get(url);

    return result;
  },
  getRequestDetail: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
  editRequestDetail: async (id: string, data: IPart) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  deleteRequest: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.delete(url);

    return result;
  },
};

export { partSupply };
