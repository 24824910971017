import { useContext } from "react";
import { AuthContext } from "../auth";
import { keycloak } from "../keycloak";

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  return {
    ready: authContext.ready,
    authenticated: authContext.authenticated,
    login: () => keycloak.login(),
    logout: () => keycloak.logout(),
    register: () => keycloak.register(),
    profile: authContext.profile,
    token: keycloak.idToken,
  };
};
