/* eslint-disable */
import { axios } from "./api";

const baseUrl = "/cars";

interface ICar {
  name: string;
  nameEn?: string;
  image?: string;
  parentId?: string;
}

const car = {
  getCarBrands: async () => {
    const url = `/ui/cars`;
    const result = await axios.get(url);

    return result;
  },
  getCarModels: async (id: string) => {
    const url = `/ui/cars/${id}/models`;
    const result = await axios.get(url);

    return result;
  },
  getCarTypes: async (id: string) => {
    const url = `${baseUrl}/${id}/types`;
    const result = await axios.get(url);

    return result;
  },
  addCar: async (data: ICar) => {
    const url = `${baseUrl}`;
    const result = await axios.post(url, data);

    return result;
  },
  editCar: async (id: string, data: ICar) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
};
export { car };
