/* eslint-disable */
import { neshanAPI } from "./neshanApi";

const map = {
  searchOnMap: async (search: string, lat: number, lng: string) => {
    const url = `/v1/search?term=${search}&lat=${lat}&lng=${lng}`;
    const result = await neshanAPI.get(url);

    return result;
  },
};
export { map };
