import Axios from "axios";
import { configs } from "../../configs";
import { vState } from "../../core/valtio";
import { keycloak } from "../../keycloak";
import { ServiceStorage } from "../services";
// import NProgress from "nprogress";

// NProgress.configure({ showSpinner: false });
// NProgress.start();
// NProgress.done();
// NProgress.remove();

const env = import.meta.env;

interface ConfigType {
  contentType?: string;
  auth?: boolean;
}

/**
 * Set axios config and handle refresh token
 * @param {object} config - Passed config
 *
 * @return {void}
 */
// export const init = (config: ConfigType = {}) => {
//     const {
//       contentType = 'application/x-www-form-urlencoded',
//       auth = false,
//     } = config;

//     axios.defaults.headers.common['Content-Type'] = contentType;

//     if (auth) {
//       const accessToken =  ServiceStorage.getToken();
//       if (accessToken) {
//         axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//       }
//     }
// };

export const axios = Axios.create({
  baseURL: configs().endpointUrl,
});

axios.interceptors.request.use(
  (config) => {
    // const savedToken = ServiceStorage.getToken();
    // let accessToken:null | string = null;

    // if(keycloak.authenticated && keycloak.token) {
    //   accessToken = keycloak.token
    // } else if (savedToken) {
    //   accessToken = savedToken;
    // }

    // if (accessToken) {
    //   config.headers = {
    //     ...(config.headers || {}),
    //     Authorization: `Bearer ${accessToken}`,
    //   };
    // }

    if (keycloak.authenticated && keycloak.idToken) {
      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${keycloak.idToken}`,
      };
    }

    return config;
  },
  (e) => Promise.reject(e),
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      console.log("401");
      const savedToken = ServiceStorage.getToken();
      if (keycloak.idToken && keycloak.idToken !== savedToken) {
        console.log("SAVED", savedToken);
        console.log("KEYCLOAK", keycloak.idToken);

        ServiceStorage.setToken(keycloak.idToken);
        err.config.headers["Authorization"] = `Bearer ${keycloak.idToken}`;
        axios.request(err.config);
      } else {
        vState.isAuthenticated = false;
        vState.userInfo = {
          groups: [],
          id: "",
          username: "",
          mobile: "",
          name: "",
          roles: [],
          userProfileId: "",
        };
        ServiceStorage.removeAuth();
        ServiceStorage.removeToken();
        keycloak.logout();
        window.location.href = `/`;
      }
    }

    return Promise.reject(err);
  },
);
