/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FiCornerUpRight } from "react-icons/fi";
import { HiMenuAlt3 } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { useSnapshot } from "valtio";
import virazh from "../../../assets/images/virazh-logo.svg";
import { vState } from "../../../core";
import { Text } from "../../components";
import { DrawerMenu } from "./DrawerMenu";

interface IHeaderProps {
  className?: string;
  showBackButton?: boolean;
  noLogo?: boolean;
  logoCenter?: boolean;
  backClick?: () => void;
  lowMargin?: boolean;
}

const Header: React.FC<IHeaderProps> = React.memo(
  ({ className, showBackButton = false, noLogo = false, backClick, logoCenter = false, lowMargin = false }) => {
    const [open, setOpen] = useState(false);
    const snap = useSnapshot(vState);
    const history = useHistory();

    const backButtonClickHandler = () => {
      if (backClick) {
        backClick();
      } else {
        history.goBack();
      }
    };

    useEffect(() => {
      // const authUser = ServiceStorage.getAuth();
      // if (authUser && authUser.access_token !== "") {
      //   vState.user = authUser;
      //   vState.isAuthenticated = true;
      // }
    }, []);

    return (
      <div
        className={`w-full px-6 max-2xs:px-3 py-3 h-16 fixed flex justify-between items-center ${className} transition-all bg-white ${
          snap.isScrolling ? "ts-bottom-shadow" : ""
        } z-50`}
      >
        <DrawerMenu open={open} onClose={() => setOpen(false)} />
        {!showBackButton && (
          <div
            onClick={() => setOpen(true)}
            className={`transition-all w-10 h-10 p-2 rounded-xl bg-white ts-shadow flex justify-center items-center cursor-pointer`}
          >
            <HiMenuAlt3 size={18} className="ts-accent" />
          </div>
        )}
        {noLogo || showBackButton ? (
          <div
            onClick={backButtonClickHandler}
            className={`transition-all h-10 px-3 rounded-xl ts-bg-gray-01 flex justify-center items-center cursor-pointer z-20`}
          >
            <FiCornerUpRight size={18} className="ts-primary" />
            <Text type="H6" className="ts-primary mr-1">
              بازگشت
            </Text>
          </div>
        ) : !noLogo ? (
          <img src={virazh} alt="logo" className="" />
        ) : null}
      </div>
    );
  },
);

export { Header };

const styles = {};
