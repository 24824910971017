/* eslint-disable */
import { axios } from "./api";

const plates = {
  editPlate: async (
    id: string,
    data: {
      mobile?: string;
      plateNumber: string;
      ownerName: string;
      carName: string;
    },
  ) => {
    const url = `/plates/${id}`;
    const result = await axios.put(url, data);

    return result;
  },
  getPlateReminders: async (id: string, page, sort?: string | undefined) => {
    const url = `/plates/${id}/reminders?page=${page}&pageSize=20${sort ? `&sort=${sort}` : ""}`;
    const result = await axios.get(url);

    return result;
  },
  changeOwnership: async (id: string, mobile: string) => {
    const url = `/plates/${id}/changeOwnership`;
    const result = await axios.put(url, { mobile });

    return result;
  },
  getPlateHistory: async (id: string) => {
    const url = `/sessions?plateId=${id}`;
    const result = await axios.get(url);

    return result;
  },
  getHistoryDetail: async (id: string) => {
    const url = `/sessions/${id}`;
    const result = await axios.get(url);

    return result;
  },
  archivePlate: async (id: string) => {
    const url = `/plates/${id}/archive`;
    const result = await axios.put(url);

    return result;
  },
};

export { plates };
