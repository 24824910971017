import { keycloak } from "../../keycloak";
import { ServiceStorage } from "../services";

const env = import.meta.env;

const ConfigApp = {
  clearSearchHeaderSession: () => {
    sessionStorage.removeItem("KEY_SEARCH_HEADER");
  },
  enableGoogleAnalytics: () => {},
  enableHotjar: () => {},
  initKeycloak: ()=>{
    keycloak.init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
    })
    .then((authenticated) => {
      console.log("authenticated: ", authenticated);
      if(!authenticated) {
        ServiceStorage.removeToken();
        ServiceStorage.removeAuth();
      }
    });
  }
};

export { ConfigApp };
