/* eslint-disable */
import React from "react";
import { useSnapshot } from "valtio";
import NotFound from "../../../assets/images/server-not-respond.svg";
import { vState } from "../../../core";
import { Button, Text } from "../../components";

//Images

interface IServerNotRespondProps {}

const ServerNotRespond: React.FC<IServerNotRespondProps> = React.memo(({}) => {
  const snap = useSnapshot(vState);

  const callBackupHandler = () => {
    window.open("tel:02191693862", "_self");
  };

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-center items-center">
      <img src={NotFound} alt="not-found" className="w-full md:w-2/3 md:h-2/3 mb-16" />
      <div className="w-full px-7 flex flex-col justify-center items-center text-center">
        <Text type="H5" className="ts-gray-07">
          مشکلی پیش آمده!
        </Text>
        <Text type="P4" className="ts-gray-07 text-center mt-3 mb-1.5">
          در صورت اطمینان از اتصال دستگاه خود به اینترنت، پس از چند دقیقه دوباره امتحان کنید.
        </Text>
        <Text type="P4" className="ts-gray-07 text-center mb-6">
          همچنین، در صورت تداوم مشکل میتوانید با پشتیبانی تماس بگیرید.
        </Text>
        <div className="w-full md:w-2/3 flex justify-between items-center xs:mb-10 gap-3">
          <Button
            onClick={() => window.location.reload()}
            bcolor="primary"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            تلاش مجدد
          </Button>
          <Button
            onClick={callBackupHandler}
            bcolor="primaryTransparent"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            تماس با پشتیبانی
          </Button>
        </div>
      </div>
    </div>
  );
});

export { ServerNotRespond };

const styles = {};
